const BeaconSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 200 200" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-140 465) scale(0.23-0.23)">
      <path
        d="M944 1921 c-55 -43 -109 -81 -122 -85 -20 -7 -22 -12 -17 -39 4 -18
10 -35 14 -39 3 -4 33 0 66 9 81 22 240 22 324 0 35 -10 66 -16 68 -13 2 2 6
19 10 39 4 30 2 36 -17 42 -12 4 -66 43 -119 86 -54 43 -100 79 -103 79 -3 0
-50 -35 -104 -79z"
      />
      <path
        d="M965 1763 c-120 -25 -120 -24 -99 -127 16 -81 19 -87 38 -81 40 13
234 18 267 6 18 -6 34 -9 37 -6 3 3 13 41 22 85 20 90 19 92 -48 110 -45 12
-182 20 -217 13z"
      />
      <path
        d="M970 1549 c-30 -4 -73 -14 -95 -22 -38 -14 -40 -17 -38 -52 2 -21 4
-39 5 -41 2 -1 37 7 78 18 87 23 206 20 280 -6 24 -8 46 -13 48 -11 2 2 7 20
12 39 l8 36 -67 19 c-83 25 -160 31 -231 20z"
      />
      <path
        d="M890 1395 c-20 -24 -3 -45 60 -73 33 -15 60 -29 60 -32 0 -3 -27 -15
-59 -28 -82 -32 -107 -60 -116 -129 -5 -44 -3 -59 9 -71 14 -15 14 -18 -9 -42
-36 -38 -56 -174 -33 -223 16 -33 105 -108 180 -151 21 -11 38 -23 38 -26 0
-3 -37 -28 -83 -57 -102 -64 -190 -142 -221 -196 -17 -29 -33 -89 -50 -187
-15 -80 -28 -153 -30 -162 -8 -34 3 -17 42 65 49 105 127 180 341 330 176 124
157 122 260 30 74 -66 84 -62 79 33 -3 51 -9 67 -37 103 -33 41 -33 42 -17 75
19 40 19 67 2 164 -16 91 -45 124 -160 183 l-80 42 65 33 c35 18 75 42 88 55
21 19 22 27 16 73 -3 30 -15 60 -26 72 -13 14 -17 24 -10 29 6 3 11 22 11 42
0 34 -1 35 -17 21 -51 -44 -70 -46 -154 -17 -43 16 -92 35 -108 43 -25 14 -30
14 -41 1z m276 -160 c20 -8 33 -19 29 -25 -8 -13 -66 -43 -165 -85 l-78 -32
-36 20 c-20 12 -35 26 -33 32 4 12 80 51 161 81 74 28 77 28 122 9z m42 -389
c34 -22 62 -43 62 -47 0 -9 -95 -89 -105 -89 -13 0 -208 112 -267 153 -32 22
-58 45 -58 51 0 6 23 26 51 43 l51 31 101 -51 c56 -27 130 -68 165 -91z"
      />
    </g>
  </svg>
);

export default BeaconSvg;
